import axios from "axios";
import { HotspotDTO } from "../entities/hotspot.entity";
import getRandomFontClasses from "../utils/getRandomFontClasses";

const API_BASE_URL = "https://chamberofsecrets.8club.co/";

const DummyData = {
  items: [
    {
      id: 1,
      name: "Manisha Verma",
      experience: {
        id: 1,
        name: "The Pixel Party",
        tagline: "House Party",
        description: "House Party at 8Villa, Bengaluru.",
        image_url: "https://picsum.photos/id/11/200/300",
        icon_url: "https://picsum.photos/id/11/50/50",
      },
      start_date: "2024-03-05",
      start_time: "00:00:00.000Z",
      end_date: "2024-03-05",
      end_time: "23:59:59.999Z",
      description: "House Party at 8Villa, Bengaluru.",
      capacity: 40,
      ticket_price: 0,
      address: {
        id: 1,
        address_line_1: "8Villa",
        address_line_2: "Bengaluru",
        city: "Bengaluru",
        state: "Karnataka",
        country: "India",
        postal_code: "560001",
        latitude: 12.9716,
        longitude: 77.5946,
      },
      host: {
        id: 1,
        status: 1,
        user: {
          id: 1,
          name: "Manisha Verma",
          gender: 1,
          social_profile_id: "string",
          status: 1,
          is_host: true,
          profile_image_url: "https://picsum.photos/200",
        },
        extras_json: {
          follower_count: 3477,
        },
      },
      assets: [
        {
          id: 1,
          purpose: 1,
          type: 1,
          key: "DSC03430",
          bucket_name: "8club-static-assets-production",
          order: 1,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03430",
        },
        {
          id: 2,
          purpose: 1,
          type: 1,
          key: "DSC03394",
          bucket_name: "8club-static-assets-production",
          order: 2,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03394",
        },
        {
          id: 3,
          purpose: 1,
          type: 1,
          key: "DSC03419",
          bucket_name: "8club-static-assets-production",
          order: 3,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03419",
        },
        {
          id: 5,
          purpose: 1,
          type: 1,
          key: "DSC03432",
          bucket_name: "8club-static-assets-production",
          order: 5,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03432",
        },
        {
          id: 6,
          purpose: 1,
          type: 1,
          key: "DSC03469",
          bucket_name: "8club-static-assets-production",
          order: 6,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03469",
        },
        {
          id: 7,
          purpose: 1,
          type: 1,
          key: "DSC03496",
          bucket_name: "8club-static-assets-production",
          order: 7,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03496",
        },
        {
          id: 8,
          purpose: 1,
          type: 1,
          key: "DSC03440",
          bucket_name: "8club-static-assets-production",
          order: 8,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03440",
        },
        {
          id: 9,
          purpose: 1,
          type: 1,
          key: "DSC03442",
          bucket_name: "8club-static-assets-production",
          order: 9,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03442",
        },
        {
          id: 11,
          purpose: 2,
          type: 1,
          key: "DSC03461",
          bucket_name: "8club-static-assets-production",
          order: 11,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03461",
        },
        {
          id: 12,
          purpose: 1,
          type: 1,
          key: "DSC03475",
          bucket_name: "8club-static-assets-production",
          order: 12,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03475",
        },
        {
          id: 13,
          purpose: 1,
          type: 1,
          key: "DSC03472",
          bucket_name: "8club-static-assets-production",
          order: 13,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03472",
        },
        {
          id: 15,
          purpose: 1,
          type: 1,
          key: "DSC03398",
          bucket_name: "8club-static-assets-production",
          order: 15,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03398",
        },
        {
          id: 16,
          purpose: 1,
          type: 1,
          key: "DSC03388",
          bucket_name: "8club-static-assets-production",
          order: 16,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03388",
        },
        {
          id: 17,
          purpose: 1,
          type: 1,
          key: "DSC03389",
          bucket_name: "8club-static-assets-production",
          order: 17,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03389",
        },
        {
          id: 18,
          purpose: 1,
          type: 1,
          key: "DSC03376",
          bucket_name: "8club-static-assets-production",
          order: 18,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03376",
        },
        {
          id: 19,
          purpose: 1,
          type: 1,
          key: "DSC03438",
          bucket_name: "8club-static-assets-production",
          order: 19,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03438",
        },
        {
          id: 20,
          purpose: 1,
          type: 1,
          key: "DSC03412",
          bucket_name: "8club-static-assets-production",
          order: 20,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03412",
        },
      ],
    },
    {
      id: 2,
      name: "Isha Beniwal",
      experience: {
        id: 2,
        name: "Summer Picnic in the Palace",
        tagline: "Picnic",
        description: "Picnic at Bangalore Palace, Bengaluru.",
        image_url:
          "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/pixel_party/DSC03412",
        icon_url: "https://picsum.photos/id/12/50/50",
      },
      start_date: "2024-02-06",
      start_time: "00:00:00.000Z",
      end_date: "2024-02-06",
      end_time: "23:59:59.999Z",
      description: "Picnic at Bangalore Palace, Bengaluru.",
      capacity: 15,
      ticket_price: 0,
      address: {
        id: 2,
        address_line_1: "Bangalore Palace",
        address_line_2: "Bengaluru",
        city: "Bengaluru",
        state: "Karnataka",
        country: "India",
        postal_code: "560001",
        latitude: 12.9716,
        longitude: 77.5946,
      },
      host: {
        id: 2,
        status: 1,
        user: {
          id: 2,
          name: "Isha Beniwal",
          gender: 1,
          social_profile_id: "string",
          status: 1,
          is_host: true,
          profile_image_url: "https://picsum.photos/200",
        },
        extras_json: {
          follower_count: 27820,
        },
      },
      assets: [
        {
          id: 1,
          purpose: 1,
          type: 1,
          key: "DSC_8096",
          bucket_name: "8club-static-assets-production",
          order: 1,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8096",
        },
        {
          id: 2,
          purpose: 1,
          type: 1,
          key: "DSC_8056",
          bucket_name: "8club-static-assets-production",
          order: 2,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8056",
        },
        {
          id: 3,
          purpose: 1,
          type: 1,
          key: "PXL_20240602_095100582",
          bucket_name: "8club-static-assets-production",
          order: 3,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/PXL_20240602_095100582",
        },
        {
          id: 4,
          purpose: 1,
          type: 1,
          key: "DSC_8047",
          bucket_name: "8club-static-assets-production",
          order: 4,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8047",
        },
        {
          id: 5,
          purpose: 1,
          type: 1,
          key: "DSC_8053",
          bucket_name: "8club-static-assets-production",
          order: 5,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8053",
        },
        {
          id: 6,
          purpose: 1,
          type: 1,
          key: "DSC_8045",
          bucket_name: "8club-static-assets-production",
          order: 6,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8045",
        },
        {
          id: 7,
          purpose: 1,
          type: 1,
          key: "DSC_8130",
          bucket_name: "8club-static-assets-production",
          order: 7,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8130",
        },
        {
          id: 8,
          purpose: 1,
          type: 1,
          key: "DSC_8140",
          bucket_name: "8club-static-assets-production",
          order: 8,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8140",
        },
        {
          id: 9,
          purpose: 1,
          type: 1,
          key: "DSC_8024",
          bucket_name: "8club-static-assets-production",
          order: 9,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8024",
        },
        {
          id: 10,
          purpose: 1,
          type: 1,
          key: "DSC_8017",
          bucket_name: "8club-static-assets-production",
          order: 10,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8017",
        },
        {
          id: 11,
          purpose: 1,
          type: 1,
          key: "DSC_8001",
          bucket_name: "8club-static-assets-production",
          order: 11,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8001",
        },
        {
          id: 12,
          purpose: 1,
          type: 1,
          key: "DSC_8100",
          bucket_name: "8club-static-assets-production",
          order: 12,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8100",
        },
        {
          id: 13,
          purpose: 1,
          type: 1,
          key: "DSC_8117",
          bucket_name: "8club-static-assets-production",
          order: 13,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8117",
        },
        {
          id: 14,
          purpose: 1,
          type: 1,
          key: "DSC_8106",
          bucket_name: "8club-static-assets-production",
          order: 14,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8106",
        },
        {
          id: 15,
          purpose: 1,
          type: 1,
          key: "DSC_8066",
          bucket_name: "8club-static-assets-production",
          order: 15,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8066",
        },
        {
          id: 16,
          purpose: 1,
          type: 1,
          key: "DSC_8072",
          bucket_name: "8club-static-assets-production",
          order: 16,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/picnic/DSC_8072",
        },
      ],
    },
    {
      id: 3,
      name: "8club",
      experience: {
        id: 3,
        name: "Kitchen Rave",
        tagline: "Party",
        description: "Party at JW Marriott Hotel, Bengaluru.",
        image_url: "https://picsum.photos/id/13/200/300",
        icon_url: "https://picsum.photos/id/13/50/50",
      },
      start_date: "2024-06-15",
      start_time: "00:00:00.000Z",
      end_date: "2024-06-15",
      end_time: "23:59:59.999Z",
      description: "Party at JW Marriott Hotel, Bengaluru.",
      capacity: 50,
      ticket_price: 0,
      address: {
        id: 3,
        address_line_1: "JW Marriott Hotel",
        address_line_2: "Bengaluru",
        city: "Bengaluru",
        state: "Karnataka",
        country: "India",
        postal_code: "560001",
        latitude: 12.9716,
        longitude: 77.5946,
      },
      host: {
        id: 3,
        status: 1,
        user: {
          id: 3,
          name: "8club",
          gender: 1,
          social_profile_id: "string",
          status: 1,
          is_host: true,
          profile_image_url: "https://picsum.photos/200",
        },
        extras_json: {
          follower_count: 1633,
        },
      },
      assets: [
        {
          id: 1,
          purpose: 1,
          type: 1,
          key: "DSC00117",
          bucket_name: "8club-static-assets-production",
          order: 1,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00117.jpg",
        },
        {
          id: 2,
          purpose: 1,
          type: 1,
          key: "DSC00040",
          bucket_name: "8club-static-assets-production",
          order: 2,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00040.png",
        },
        {
          id: 3,
          purpose: 1,
          type: 1,
          key: "DSC00067",
          bucket_name: "8club-static-assets-production",
          order: 3,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00067.jpg",
        },
        {
          id: 4,
          purpose: 1,
          type: 1,
          key: "DSC00077",
          bucket_name: "8club-static-assets-production",
          order: 4,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00077.jpg",
        },
        {
          id: 5,
          purpose: 1,
          type: 1,
          key: "DSC00058",
          bucket_name: "8club-static-assets-production",
          order: 5,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00058.png",
        },
        {
          id: 6,
          purpose: 1,
          type: 1,
          key: "DSC00031",
          bucket_name: "8club-static-assets-production",
          order: 6,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00031.png",
        },
        {
          id: 7,
          purpose: 1,
          type: 1,
          key: "DSC00025",
          bucket_name: "8club-static-assets-production",
          order: 7,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00025.jpg",
        },
        {
          id: 8,
          purpose: 1,
          type: 1,
          key: "DSC00069",
          bucket_name: "8club-static-assets-production",
          order: 8,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00069.jpg",
        },
        {
          id: 9,
          purpose: 1,
          type: 1,
          key: "DSC00111",
          bucket_name: "8club-static-assets-production",
          order: 9,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00111.jpg",
        },
        {
          id: 10,
          purpose: 1,
          type: 1,
          key: "DSC00073",
          bucket_name: "8club-static-assets-production",
          order: 10,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00073.jpg",
        },
        {
          id: 11,
          purpose: 1,
          type: 1,
          key: "DSC00102",
          bucket_name: "8club-static-assets-production",
          order: 11,
          url: "https://8club-static-assets-production.s3.amazonaws.com/assets/wall/kitchen_rave/DSC00102.jpg",
        },
      ],
    },
    {
      id: 4,
      name: "Sukhdeep Bahra",
      experience: {
        id: 4,
        name: "A Cocktail Soirée",
        tagline: "Liquor Tasting",
        description: "Liquor Tasting at One8 Commune, Bengaluru.",
        image_url: "https://picsum.photos/id/14/200/300",
        icon_url: "https://picsum.photos/id/14/50/50",
      },
      start_date: "2024-06-20",
      start_time: "00:00:00.000Z",
      end_date: "2024-06-20",
      end_time: "23:59:59.999Z",
      description: "Liquor Tasting at One8 Commune, Bengaluru.",
      capacity: 20,
      ticket_price: 0,
      address: {
        id: 4,
        address_line_1: "One8 Commune",
        address_line_2: "Bengaluru",
        city: "Bengaluru",
        state: "Karnataka",
        country: "India",
        postal_code: "560001",
        latitude: 12.9716,
        longitude: 77.5946,
      },
      host: {
        id: 4,
        status: 1,
        user: {
          id: 4,
          name: "Sukhdeep Bahra",
          gender: 1,
          social_profile_id: "string",
          status: 1,
          is_host: true,
          profile_image_url: "https://picsum.photos/200",
        },
        extras_json: {
          follower_count: 5265,
        },
      },
      assets: [
        {
          id: 1,
          purpose: 1,
          type: 1,
          key: "DSC00473",
          bucket_name: "8club-static-assets-production",
          order: 1,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00473",
        },
        {
          id: 2,
          purpose: 1,
          type: 1,
          key: "DSC00248",
          bucket_name: "8club-static-assets-production",
          order: 2,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00248",
        },
        {
          id: 3,
          purpose: 1,
          type: 1,
          key: "DSC00272",
          bucket_name: "8club-static-assets-production",
          order: 3,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00272",
        },
        {
          id: 4,
          purpose: 1,
          type: 1,
          key: "DSC00488",
          bucket_name: "8club-static-assets-production",
          order: 4,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00488",
        },
        {
          id: 5,
          purpose: 1,
          type: 1,
          key: "DSC00216",
          bucket_name: "8club-static-assets-production",
          order: 5,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00216",
        },
        {
          id: 6,
          purpose: 1,
          type: 1,
          key: "DSC00360",
          bucket_name: "8club-static-assets-production",
          order: 6,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00360",
        },
        {
          id: 7,
          purpose: 1,
          type: 1,
          key: "DSC00401",
          bucket_name: "8club-static-assets-production",
          order: 7,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00401",
        },
        {
          id: 8,
          purpose: 1,
          type: 1,
          key: "DSC00212",
          bucket_name: "8club-static-assets-production",
          order: 8,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00212",
        },
        {
          id: 9,
          purpose: 1,
          type: 1,
          key: "DSC00401",
          bucket_name: "8club-static-assets-production",
          order: 9,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00401(1)",
        },
        {
          id: 10,
          purpose: 1,
          type: 1,
          key: "DSC00381",
          bucket_name: "8club-static-assets-production",
          order: 10,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00381",
        },
        {
          id: 11,
          purpose: 1,
          type: 1,
          key: "DSC00230",
          bucket_name: "8club-static-assets-production",
          order: 11,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00230",
        },
        {
          id: 12,
          purpose: 1,
          type: 1,
          key: "DSC00422",
          bucket_name: "8club-static-assets-production",
          order: 12,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00422",
        },
        {
          id: 13,
          purpose: 1,
          type: 1,
          key: "DSC00452",
          bucket_name: "8club-static-assets-production",
          order: 13,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00452",
        },
        {
          id: 14,
          purpose: 1,
          type: 1,
          key: "DSC00297",
          bucket_name: "8club-static-assets-production",
          order: 14,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00297",
        },
        {
          id: 15,
          purpose: 1,
          type: 1,
          key: "DSC00445",
          bucket_name: "8club-static-assets-production",
          order: 15,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00445",
        },
        {
          id: 16,
          purpose: 1,
          type: 1,
          key: "DSC00292",
          bucket_name: "8club-static-assets-production",
          order: 16,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/cocktail/DSC00292",
        },
      ],
    },
    {
      id: 5,
      name: "Arjun Prasannan",
      experience: {
        id: 5,
        name: "The Trance Girl - Book Launch",
        tagline: "Lit Meet",
        description: "Lit Meet at Urban Solace, Bengaluru.",
        image_url: "https://picsum.photos/id/15/200/300",
        icon_url: "https://picsum.photos/id/15/50/50",
      },
      start_date: "2024-07-14",
      start_time: "00:00:00.000Z",
      end_date: "2024-07-14",
      end_time: "23:59:59.999Z",
      description: "Lit Meet at Urban Solace, Bengaluru.",
      capacity: 15,
      ticket_price: 0,
      address: {
        id: 5,
        address_line_1: "Urban Solace",
        address_line_2: "Bengaluru",
        city: "Bengaluru",
        state: "Karnataka",
        country: "India",
        postal_code: "560001",
        latitude: 12.9716,
        longitude: 77.5946,
      },
      host: {
        id: 5,
        status: 1,
        user: {
          id: 5,
          name: "Arjun Prasannan",
          gender: 1,
          social_profile_id: "string",
          status: 1,
          is_host: true,
          profile_image_url: "https://picsum.photos/200",
        },
        extras_json: {
          follower_count: 4218,
        },
      },
      assets: [
        {
          id: 1,
          purpose: 1,
          type: 1,
          key: "DSC03864",
          bucket_name: "8club-static-assets-production",
          order: 1,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03864",
        },
        {
          id: 2,
          purpose: 1,
          type: 1,
          key: "DSC03910",
          bucket_name: "8club-static-assets-production",
          order: 2,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03910",
        },
        {
          id: 3,
          purpose: 1,
          type: 1,
          key: "DSC03898",
          bucket_name: "8club-static-assets-production",
          order: 3,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03898",
        },
        {
          id: 4,
          purpose: 1,
          type: 1,
          key: "DSC03907",
          bucket_name: "8club-static-assets-production",
          order: 4,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03907",
        },
        {
          id: 5,
          purpose: 1,
          type: 1,
          key: "DSC03866",
          bucket_name: "8club-static-assets-production",
          order: 5,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03866",
        },
        {
          id: 6,
          purpose: 1,
          type: 1,
          key: "DSC03876",
          bucket_name: "8club-static-assets-production",
          order: 6,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03876",
        },
        {
          id: 7,
          purpose: 1,
          type: 1,
          key: "DSC03914",
          bucket_name: "8club-static-assets-production",
          order: 7,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03914",
        },
        {
          id: 8,
          purpose: 1,
          type: 1,
          key: "DSC03900",
          bucket_name: "8club-static-assets-production",
          order: 8,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03900",
        },
        {
          id: 9,
          purpose: 1,
          type: 1,
          key: "DSC03860",
          bucket_name: "8club-static-assets-production",
          order: 9,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03860",
        },
        {
          id: 11,
          purpose: 1,
          type: 1,
          key: "DSC03924",
          bucket_name: "8club-static-assets-production",
          order: 11,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03924",
        },
        {
          id: 12,
          purpose: 1,
          type: 1,
          key: "DSC03852",
          bucket_name: "8club-static-assets-production",
          order: 12,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03852",
        },
        {
          id: 13,
          purpose: 1,
          type: 1,
          key: "DSC03853",
          bucket_name: "8club-static-assets-production",
          order: 13,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03853",
        },
        {
          id: 14,
          purpose: 1,
          type: 1,
          key: "DSC03923",
          bucket_name: "8club-static-assets-production",
          order: 14,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03923",
        },
        {
          id: 15,
          purpose: 1,
          type: 1,
          key: "DSC03882",
          bucket_name: "8club-static-assets-production",
          order: 15,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/trance/DSC03882",
        },
      ],
    },
    {
      id: 6,
      name: "Kunzang Chawla",
      experience: {
        id: 6,
        name: "The Kingfisher Tower Party",
        tagline: "Party",
        description: "Party at Prestige Kingfisher Towers, Bengaluru.",
        image_url: "https://picsum.photos/id/16/200/300",
        icon_url: "https://picsum.photos/id/16/50/50",
      },
      start_date: "2024-07-19",
      start_time: "00:00:00.000Z",
      end_date: "2024-07-19",
      end_time: "23:59:59.999Z",
      description: "Party at Prestige Kingfisher Towers, Bengaluru.",
      capacity: 80,
      ticket_price: 0,
      address: {
        id: 6,
        address_line_1: "Prestige Kingfisher Towers",
        address_line_2: "Bengaluru",
        city: "Bengaluru",
        state: "Karnataka",
        country: "India",
        postal_code: "560001",
        latitude: 12.9716,
        longitude: 77.5946,
      },
      host: {
        id: 6,
        status: 1,
        user: {
          id: 6,
          name: "Kunzang Chawla",
          gender: 1,
          social_profile_id: "string",
          status: 1,
          is_host: true,
          profile_image_url: "https://picsum.photos/200",
        },
        extras_json: {
          follower_count: 2357,
        },
      },
      assets: [
        {
          id: 1,
          purpose: 1,
          type: 1,
          key: "DSC_8963",
          bucket_name: "8club-static-assets-production",
          order: 1,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208963",
        },
        {
          id: 2,
          purpose: 1,
          type: 1,
          key: "DSC_8745",
          bucket_name: "8club-static-assets-production",
          order: 2,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208745",
        },
        {
          id: 3,
          purpose: 1,
          type: 1,
          key: "DSC_8828",
          bucket_name: "8club-static-assets-production",
          order: 3,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208828",
        },
        {
          id: 4,
          purpose: 1,
          type: 1,
          key: "DSC_9263",
          bucket_name: "8club-static-assets-production",
          order: 4,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%209263",
        },
        {
          id: 5,
          purpose: 1,
          type: 1,
          key: "DSC_9262",
          bucket_name: "8club-static-assets-production",
          order: 5,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%209262",
        },
        {
          id: 6,
          purpose: 1,
          type: 1,
          key: "DSC_8829",
          bucket_name: "8club-static-assets-production",
          order: 6,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208829",
        },
        {
          id: 7,
          purpose: 1,
          type: 1,
          key: "DSC_8801",
          bucket_name: "8club-static-assets-production",
          order: 7,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208801",
        },
        {
          id: 8,
          purpose: 1,
          type: 1,
          key: "DSC_8965",
          bucket_name: "8club-static-assets-production",
          order: 8,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208965",
        },
        {
          id: 10,
          purpose: 1,
          type: 1,
          key: "DSC_9305",
          bucket_name: "8club-static-assets-production",
          order: 10,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%209305",
        },
        {
          id: 11,
          purpose: 1,
          type: 1,
          key: "DSC_9201",
          bucket_name: "8club-static-assets-production",
          order: 11,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%209201",
        },
        {
          id: 12,
          purpose: 1,
          type: 1,
          key: "DSC_8913",
          bucket_name: "8club-static-assets-production",
          order: 12,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208913",
        },
        {
          id: 13,
          purpose: 1,
          type: 1,
          key: "DSC_9011",
          bucket_name: "8club-static-assets-production",
          order: 13,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%209011",
        },
        {
          id: 14,
          purpose: 1,
          type: 1,
          key: "DSC_8858",
          bucket_name: "8club-static-assets-production",
          order: 14,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208858",
        },
        {
          id: 15,
          purpose: 1,
          type: 1,
          key: "DSC_9223",
          bucket_name: "8club-static-assets-production",
          order: 15,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%209223",
        },
        {
          id: 16,
          purpose: 1,
          type: 1,
          key: "DSC_9182",
          bucket_name: "8club-static-assets-production",
          order: 16,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%209182",
        },
        {
          id: 17,
          purpose: 1,
          type: 1,
          key: "DSC_8845",
          bucket_name: "8club-static-assets-production",
          order: 17,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208845",
        },
        {
          id: 18,
          purpose: 1,
          type: 1,
          key: "DSC_8758",
          bucket_name: "8club-static-assets-production",
          order: 18,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208758",
        },
        {
          id: 19,
          purpose: 1,
          type: 1,
          key: "DSC_8772",
          bucket_name: "8club-static-assets-production",
          order: 19,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kingfisher/DSC%208762",
        },
      ],
    },
    {
      id: 7,
      name: "Noopur Artul",
      experience: {
        id: 7,
        name: "Tilt House Parties",
        tagline: "House Party",
        description: "House Party at 8Villa, Bengaluru.",
        image_url: "https://picsum.photos/id/17/200/300",
        icon_url: "https://picsum.photos/id/17/50/50",
      },
      start_date: "2024-07-20",
      start_time: "00:00:00.000Z",
      end_date: "2024-07-20",
      end_time: "23:59:59.999Z",
      description: "House Party at 8Villa, Bengaluru.",
      capacity: 80,
      ticket_price: 0,
      address: {
        id: 7,
        address_line_1: "8Villa",
        address_line_2: "Bengaluru",
        city: "Bengaluru",
        state: "Karnataka",
        country: "India",
        postal_code: "560001",
        latitude: 12.9716,
        longitude: 77.5946,
      },
      host: {
        id: 7,
        status: 1,
        user: {
          id: 7,
          name: "Noopur Artul",
          gender: 1,
          social_profile_id: "string",
          status: 1,
          is_host: true,
          profile_image_url: "https://picsum.photos/200",
        },
        extras_json: {
          follower_count: 48650,
        },
      },
      assets: [
        {
          id: 1,
          purpose: 1,
          type: 1,
          key: "DSC_9388",
          bucket_name: "8club-static-assets-production",
          order: 1,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9388",
        },
        {
          id: 2,
          purpose: 1,
          type: 1,
          key: "DSC_9407",
          bucket_name: "8club-static-assets-production",
          order: 2,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9407",
        },
        {
          id: 3,
          purpose: 1,
          type: 1,
          key: "DSC_9605",
          bucket_name: "8club-static-assets-production",
          order: 3,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9605",
        },
        {
          id: 4,
          purpose: 1,
          type: 1,
          key: "DSC_9358",
          bucket_name: "8club-static-assets-production",
          order: 4,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9358",
        },
        {
          id: 5,
          purpose: 1,
          type: 1,
          key: "DSC_9500",
          bucket_name: "8club-static-assets-production",
          order: 5,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9500",
        },
        {
          id: 6,
          purpose: 1,
          type: 1,
          key: "DSC_9648",
          bucket_name: "8club-static-assets-production",
          order: 6,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9648",
        },
        {
          id: 7,
          purpose: 1,
          type: 1,
          key: "DSC_9532",
          bucket_name: "8club-static-assets-production",
          order: 7,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9532",
        },
        {
          id: 8,
          purpose: 1,
          type: 1,
          key: "DSC_9719",
          bucket_name: "8club-static-assets-production",
          order: 8,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9719",
        },
        {
          id: 9,
          purpose: 1,
          type: 1,
          key: "DSC_9451",
          bucket_name: "8club-static-assets-production",
          order: 9,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9451",
        },
        {
          id: 10,
          purpose: 1,
          type: 1,
          key: "DSC_9331",
          bucket_name: "8club-static-assets-production",
          order: 10,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9331",
        },
        {
          id: 11,
          purpose: 1,
          type: 1,
          key: "DSC_9418",
          bucket_name: "8club-static-assets-production",
          order: 11,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9418",
        },
        {
          id: 12,
          purpose: 1,
          type: 1,
          key: "DSC_9346",
          bucket_name: "8club-static-assets-production",
          order: 12,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9346",
        },
        {
          id: 13,
          purpose: 1,
          type: 1,
          key: "DSC_9409",
          bucket_name: "8club-static-assets-production",
          order: 13,
          url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/tilt/DSC_9409",
        },
      ],
    },
    // {
    //   id: 3,
    //   name: "8club",
    //   experience: {
    //     id: 3,
    //     name: "Kitchen Rave",
    //     tagline: "Party",
    //     description: "Party at JW Marriott Hotel, Bengaluru.",
    //     image_url: "https://picsum.photos/id/13/200/300",
    //     icon_url: "https://picsum.photos/id/13/50/50",
    //   },
    //   start_date: "2024-06-15",
    //   start_time: "00:00:00.000Z",
    //   end_date: "2024-06-15",
    //   end_time: "23:59:59.999Z",
    //   description: "Party at JW Marriott Hotel, Bengaluru.",
    //   capacity: 50,
    //   ticket_price: 0,
    //   address: {
    //     id: 3,
    //     address_line_1: "JW Marriott Hotel",
    //     address_line_2: "Bengaluru",
    //     city: "Bengaluru",
    //     state: "Karnataka",
    //     country: "India",
    //     postal_code: "560001",
    //     latitude: 12.9716,
    //     longitude: 77.5946,
    //   },
    //   host: {
    //     id: 3,
    //     status: 1,
    //     user: {
    //       id: 3,
    //       name: "8club",
    //       gender: 1,
    //       social_profile_id: "string",
    //       status: 1,
    //       is_host: true,
    //       profile_image_url: "https://picsum.photos/200",
    //     },
    //     extras_json: {
    //       follower_count: 100,
    //     },
    //   },
    //   assets: [
    //     {
    //       id: 1,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00117",
    //       bucket_name: "8club-static-assets-production",
    //       order: 1,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00117",
    //     },
    //     {
    //       id: 2,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00077",
    //       bucket_name: "8club-static-assets-production",
    //       order: 2,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00077",
    //     },
    //     {
    //       id: 3,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00102",
    //       bucket_name: "8club-static-assets-production",
    //       order: 3,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00102",
    //     },
    //     {
    //       id: 4,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00111",
    //       bucket_name: "8club-static-assets-production",
    //       order: 4,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00111",
    //     },
    //     {
    //       id: 5,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00058",
    //       bucket_name: "8club-static-assets-production",
    //       order: 5,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00058",
    //     },
    //     {
    //       id: 6,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00067",
    //       bucket_name: "8club-static-assets-production",
    //       order: 6,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00067",
    //     },
    //     {
    //       id: 7,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00073",
    //       bucket_name: "8club-static-assets-production",
    //       order: 7,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00073",
    //     },
    //     {
    //       id: 8,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00025",
    //       bucket_name: "8club-static-assets-production",
    //       order: 8,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00025",
    //     },
    //     {
    //       id: 9,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00031",
    //       bucket_name: "8club-static-assets-production",
    //       order: 9,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00031",
    //     },
    //     {
    //       id: 10,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00069",
    //       bucket_name: "8club-static-assets-production",
    //       order: 10,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00069",
    //     },
    //     {
    //       id: 11,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00040",
    //       bucket_name: "8club-static-assets-production",
    //       order: 11,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00040",
    //     },
    //   ],
    // },
    // {
    //   id: 3,
    //   name: "8club",
    //   experience: {
    //     id: 3,
    //     name: "Kitchen Rave",
    //     tagline: "Party",
    //     description: "Party at JW Marriott Hotel, Bengaluru.",
    //     image_url: "https://picsum.photos/id/13/200/300",
    //     icon_url: "https://picsum.photos/id/13/50/50",
    //   },
    //   start_date: "2024-06-15",
    //   start_time: "00:00:00.000Z",
    //   end_date: "2024-06-15",
    //   end_time: "23:59:59.999Z",
    //   description: "Party at JW Marriott Hotel, Bengaluru.",
    //   capacity: 50,
    //   ticket_price: 0,
    //   address: {
    //     id: 3,
    //     address_line_1: "JW Marriott Hotel",
    //     address_line_2: "Bengaluru",
    //     city: "Bengaluru",
    //     state: "Karnataka",
    //     country: "India",
    //     postal_code: "560001",
    //     latitude: 12.9716,
    //     longitude: 77.5946,
    //   },
    //   host: {
    //     id: 3,
    //     status: 1,
    //     user: {
    //       id: 3,
    //       name: "8club",
    //       gender: 1,
    //       social_profile_id: "string",
    //       status: 1,
    //       is_host: true,
    //       profile_image_url: "https://picsum.photos/200",
    //     },
    //     extras_json: {
    //       follower_count: 100,
    //     },
    //   },
    //   assets: [
    //     {
    //       id: 1,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00117",
    //       bucket_name: "8club-static-assets-production",
    //       order: 1,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00117",
    //     },
    //     {
    //       id: 2,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00077",
    //       bucket_name: "8club-static-assets-production",
    //       order: 2,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00077",
    //     },
    //     {
    //       id: 3,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00102",
    //       bucket_name: "8club-static-assets-production",
    //       order: 3,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00102",
    //     },
    //     {
    //       id: 4,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00111",
    //       bucket_name: "8club-static-assets-production",
    //       order: 4,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00111",
    //     },
    //     {
    //       id: 5,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00058",
    //       bucket_name: "8club-static-assets-production",
    //       order: 5,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00058",
    //     },
    //     {
    //       id: 6,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00067",
    //       bucket_name: "8club-static-assets-production",
    //       order: 6,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00067",
    //     },
    //     {
    //       id: 7,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00073",
    //       bucket_name: "8club-static-assets-production",
    //       order: 7,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00073",
    //     },
    //     {
    //       id: 8,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00025",
    //       bucket_name: "8club-static-assets-production",
    //       order: 8,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00025",
    //     },
    //     {
    //       id: 9,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00031",
    //       bucket_name: "8club-static-assets-production",
    //       order: 9,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00031",
    //     },
    //     {
    //       id: 10,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00069",
    //       bucket_name: "8club-static-assets-production",
    //       order: 10,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00069",
    //     },
    //     {
    //       id: 11,
    //       purpose: 1,
    //       type: 1,
    //       key: "DSC00040",
    //       bucket_name: "8club-static-assets-production",
    //       order: 11,
    //       url: "https://d9uvs0t2o3h8z.cloudfront.net/assets/wall/kitchen_rave/DSC00040",
    //     },
    //   ],
    // },
  ],
  // total: 0,
  // page: 0,
  // size: 0,
  // pages: 0,
  // links: {
  //   first: "/api/v1/users?limit=1&offset1",
  //   last: "/api/v1/users?limit=1&offset1",
  //   self: "/api/v1/users?limit=1&offset1",
  //   next: "/api/v1/users?limit=1&offset1",
  //   prev: "/api/v1/users?limit=1&offset1",
  // },
};

export const getPastHotspots = async () => {
  // const { data } = await axios.get(API_BASE_URL + "hotspots");
  //   if (!data || !data.items || !data.items.length) {
  //     return [];
  //   } // Commented out to use dummy data
  const hotspots = DummyData.items.map((h) => parseRawData(h));
  // if hotspot < 10 fill with dummy data randomly
  if (hotspots.length < 10) {
    for (let i = 0; i < 10 - hotspots.length; i++) {
      const randomIndex = Math.floor(
        Math.random() * (DummyData.items.length - 1)
      );
      hotspots.push(hotspots[randomIndex]);
    }
    return hotspots;
  }
};

const parseRawData = (data) => {
  if (!data) {
    return null;
  }
  const params = {
    id: data.id,
    title: data.experience.name,
    venue: data.address.address_line_1,
    date: data.start_date,
    host: {
      name: data.host.user.name,
      profileImageUrl: data.assets[data.assets.length - 1].url,
      followerCount: data.host.extras_json.follower_count,
    },
    inviteeCount: data.capacity,
    experience: {
      name: data.experience.name,
      tagline: data.experience.tagline,
      description: data.experience.description,
      image_url: data.experience.image_url,
      icon_url: data.experience.icon_url,
    },
    assets: data.assets,
    font: getRandomFontClasses(),
  };
  return new HotspotDTO(params);
};
